<template>
  <div class="chips">
    <div v-if="!!list.length" class="container">
      <div v-for="item of list" :key="item" class="container-chip">
        <Tag
          :value="$t(item)"
          :icon="icon(item)"
          v-tooltip.top="item ? $t(item + 'Hint') : ''"
          class="custom-chip"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tag from 'primevue/tag';
import Tooltip from 'primevue/tooltip';

export default {
  name: 'Chips',
  components: { /*Chip,*/ Tag },
  directives: {
    tooltip: Tooltip,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  beforeDestroy() {},
  methods: {
    icon(type) {
      if (type === 'owner') return 'pi pi-user';
      if (type === 'auth') return 'pi pi-lock';
      if (type === 'all') return 'pi pi-lock-open';
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "owner": "Владелец",
    "auth": "Авторизованные",
    "all": "Все",
    "ownerHint": "Владелец контейнера",
    "authHint": "Любой авторизованный пользователь AWS S3",
    "allHint": "Любой пользователь"

  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.container{
  display: flex;
  margin-left: 0.75rem

    & + & {
      margin-left: 1rem
    }

}
.custom-chip {
  background-color: #64748b78;
  color: #212529;
  border-radius: 16px;
  padding: 0 .75rem;
  font-size: 14px
}
</style>
