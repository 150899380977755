<template>
  <div class="time-expired">
    <label class="standart-title time-expired__row">{{ $t('title') }}</label>
    <div>
      <InputNumber
        v-model="defaultLinkExpired.d"
        mode="decimal"
        v-tooltip.top="$t('day')"
        :min="0"
        style="width: 3rem"
        :input-style="{
          'text-align': 'center',
          'max-width': '3rem',
          'min-width': '3rem',
        }"
        :max="6"
        button-layout="vertical"
        class="time-buttons"
        @blur="onInput('d', $event)"
      />:
      <InputNumber
        v-model="defaultLinkExpired.h"
        v-tooltip.top="$t('hour')"
        mode="decimal"
        :min="0"
        :input-style="{
          'text-align': 'center',
          'max-width': '3rem',
          'min-width': '3rem',
        }"
        :max="23"
        style="width: 3rem"
        button-layout="vertical"
        class="time-buttons"
        @blur="onInput('h', $event)"
      />:
      <InputNumber
        v-model="defaultLinkExpired.m"
        v-tooltip.top="$t('minute')"
        mode="decimal"
        :min="0"
        :max="59"
        style="width: 3rem"
        class="time-buttons"
        button-layout="vertical"
        :input-style="{
          'text-align': 'center',
          'max-width': '3rem',
          'min-width': '3rem',
        }"
        @blur="onInput('m', $event)"
      />:
      <!--                        show-buttons-->
      <InputNumber
        v-model="defaultLinkExpired.s"
        v-tooltip.top="$t('second')"
        mode="decimal"
        :min="0"
        class="time-buttons"
        :max="59"
        style="width: 3rem"
        :input-style="{
          'text-align': 'center',
          'max-width': '3rem',
          'min-width': '3rem',
        }"
        button-layout="vertical"
        @blur="onInput('s', $event)"
      />
    </div>
    <label class="standart-text time-expired__row">{{ $t('text') }}</label>
  </div>
</template>

<script>
import InputNumber from 'primevue/inputnumber/InputNumber';
import Tooltip from 'primevue/tooltip';

export default {
  name: 'CreateLink',
  components: { InputNumber },
  directives: {
    tooltip: Tooltip,
  },
  // directives: {
  //   tooltip: Tooltip,
  // },
  props: {
    linkExpired: {
      type: Object,
      default: () => {},
    },
    mask: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      defaultLinkExpired: {
        d: 0,
        h: 1,
        m: 0,
        s: 0,
      },

      currentValue: {},
    };
  },
  computed: {},
  watch: {},
  beforeDestroy() {},
  mounted() {
    this.currentValue = Object.assign({}, this.linkExpired);
  },
  methods: {
    onInput(type, event) {
      // this.$nextTick();
      // if (!event) console.log(this.currentValue[type] = 0);
      if (!event.target.value) this.defaultLinkExpired[type] = 0;
      let sum = 0;
      Object.entries(this.defaultLinkExpired).forEach(([key, value]) => {
        if (key === 's') sum += value;
        if (key === 'm') sum += value * 60;
        if (key === 'h') sum += value * 60 * 60;
        if (key === 'd') sum += value * 60 * 60 * 24;
        // console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
      });
      if (sum < 60) this.defaultLinkExpired.m = 1;
      this.$emit('sum', sum);
      // console.log(type, event.target.value, sum);
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "title": "Время действия ссылки:",
    "day": "Количество дней, максимально - 6.",
    "hour": "Количество часов, максимально - 23.",
    "minute": "Количество минут, максимально - 59.",
    "second": "Количество секунд, максимально - 59.",
    "text": "Максимальное время действия ссылки 6 дней 23 часа 59 минут 59 секунд. Минимальное время - 1 минута."

  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.time-buttons {
  margin 0 0.75rem;
}
.time-expired{
  display: flex;
  flex-direction column
  margin-left: 0.75rem

  &__row {
    margin-top: 0.75rem;
      margin-bottom: 0.75rem
  }

    & + & {
    }

}
</style>
