var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"backup"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{staticClass:"backup__content"},[_c('div',{staticClass:"backup__form"},[_c('div',{staticClass:"l-col"},[(_vm.countOfDeleteObjectsIsOne)?_c('p',{staticClass:"standart-text",domProps:{"innerHTML":_vm._s(_vm.textForDelete)}}):_vm._e(),(!_vm.countOfDeleteObjectsIsOne)?_c('div',{staticClass:"backup-block"},[_c('p',[_c('label',{staticClass:"standart-text"},[_vm._v(_vm._s(_vm.$t('labelDel')))]),_c('label',{staticClass:"standart-text"},[_vm._v(_vm._s(_vm.textForDelete))])]),_c('label',{staticClass:"standart-text"},[_vm._v(_vm._s(_vm.$t('labelAlert')))]),_c('label',{staticClass:"standart-text"},[_vm._v(_vm._s(_vm.$t('labelSure')))])]):_vm._e(),_c('base-input',{staticClass:"backup-form__field backup-form__field--input",attrs:{"placeholder":_vm.$t('backup.placeholder', {
                obj: _vm.countOfDeleteObjectsIsOne ? 'имя объекта' : 'количество элементов',
              }),"size":"small","label":_vm.$t('backup.label', {
                obj: _vm.countOfDeleteObjectsIsOne
                  ? 'имя удаляемого объекта'
                  : 'количество удаляемых элементов',
              })},on:{"input":_vm.onChange},model:{value:(_vm.nameStorage),callback:function ($$v) {_vm.nameStorage=$$v},expression:"nameStorage"}})],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }