<template>
  <div>
    <div class="folder">
      <transition name="fade" mode="out-in">
        <div class="folder__content">
          <base-select
            v-model="selectFolder"
            :options="folders"
            :searchable="false"
            :custom-label="nameFolder"
            track-by="name"
            :preselect-first="true"
            :allow-empty="false"
            :field-label="$t('label')"
            size="medium"
            open-direction="top"
            :max-height="180"
            class="folder__content-select"
            :show-labels="false"
            @input="onChange('emitFolder', $event)"
          />
          <base-input
            v-model="name"
            type="text"
            :custom-error-messages="patternValidation"
            :name="$t('balancer.name')"
            :required="true"
            use-reactive-validation
            :label="$t('balancer.name')"
            :pattern="error ? 'true' : name"
            :hint="$t('balancer.hint')"
            class="folder__content-input"
            @input="onChange('folder', $event)"
          >
          </base-input>
          <!--          </div>-->
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import BaseInput from '@/components/BaseInput/BaseInput';
import BaseRadio from '@/components/Checkboxes/BaseRadio';
import BaseSelect from '@/components/Select/BaseSelect';
import networks from '@/layouts/Stack/mixins/networks';
import storeMixin from '@/layouts/Stack/mixins';
import AddNewRule from '@/layouts/Stack/pages/Main/components/AddNewRule';
import BaseButton from '@/components/Buttons/BaseButton';
import showErrorModal from '@/mixins/showErrorModal';
import attachFloatingIp from '@/mixins/attachFloatingIp';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';

export default {
  name: 'NewFolder',
  components: {
    BaseInput,
    BaseSelect,
  },
  mixins: [],
  props: {
    storageid: {
      type: String,
      default: '',
    },
    folders: {
      type: Array,
      default: () => [],
    },
    storage: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      name: '',
      selectFolder: '',
      // folders: [],
      error: '',
    };
  },
  computed: {
    patternValidation() {
      return {
        // customValidationMsgs: {
        confirm: {
          patternMismatch: `${this.error ? this.$t(this.error) : null}`,
        },
        // },
      };
    },
    project() {
      return this.$store.getters['moduleStack/project_id'];
    },
    objects() {
      return this.$store.getters['moduleStack/getObjectsOfCurrentStorage'];
    },
  },
  watch: {
    name: {
      handler: function (event) {
        if (event) {
          // console.log(event);
          const folder =
            this.selectFolder.name === '/' ? event : this.selectFolder.name + '/' + event;
          // console.log('name', folder);
          if (
            this.folders
              .filter(t => t.type === 'object')
              .map(x => x.name)
              .includes(folder)
          )
            this.error = 'include';
          else if (event.includes('/')) this.error = 'slash';
          else if (event.length < 1 || event.length > 63) this.error = 'length';
          else this.error = '';
        } else this.error = 'length';
      },
    },
    selectFolder: {
      handler: function (event) {
        if (event) {
          // console.log('selectFolder', event);
          const folder = event.name === '/' ? this.name : event.name + '/' + this.name;
          // console.log(folder, 'selectFolder');
          if (
            this.folders
              .filter(t => t.type === 'object')
              .map(x => x.name)
              .includes(folder)
          )
            this.error = 'include';
          else if (this.name.length < 1 || this.name.length > 63) this.error = 'length';
          else this.error = '';
        }
      },
    },
    error: {
      handler: function (event) {
        // console.log(event);
        if (event) this.$emit('notready');
      },
    },
  },
  async mounted() {
    // await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'projects');
    this.$emit('notready');
  },
  methods: {
    nameFolder({ name }) {
      return name;
    },
    onChange(name, event) {
      // console.log(`${this.selectFolder}/${event}`);
      // console.log(name, event);
      let sendEmit;
      if (!!event.length) {
        if (!this.error && this.selectFolder.name === '/' && name === 'folder') {
          sendEmit = event + '/';
          this.$emit('change', sendEmit);
        } else if (!this.error && this.selectFolder.name !== '/' && name === 'folder' && event) {
          sendEmit = `${this.selectFolder.name}${event}/`;
          this.$emit('change', sendEmit);
        } else if (!this.error && this.selectFolder.name === '/' && name !== 'folder') {
          sendEmit = this.name + '/';
          this.$emit('change', sendEmit);
        } else if (!this.error && this.selectFolder.name !== '/' && name !== 'folder') {
          sendEmit = `${event}${this.name}/`;
          this.$emit('change', sendEmit);
        } else this.$emit('notready');
      }

      // if (!this.error) {
      //   name === 'folder' ? this.$emit('change', event) : this.$emit('change', event);
      // }
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "title": "Новая папка:",
    "error": "Недопустимый IP-адрес",
    "used": "Папка с таким именем уже есть.",
    "slash": "Недопустимый символ -'/'.",
    "include": "Такая директория уже есть.",
    "length": "Недопустима длина имени папки.",
    "type": "Тип:",
    "label": "Выбрать папку:",
    "day": "День",
    "hour": "Час",
    "month": "Месяц",
    "subnet": "Подсеть",
    "attachTo": "Подключить",
    "newRule": "Добавить правило",
    "newBalancer": "Создать балансировщик",
    "addRule": "Добавить еще правило",
    "port": "IP балансировщика",
    "portAviable": "IP балансировщика",
    "public": "Подключить плавающий IP",
    "warnings": "Обращаем ваше внимание на то, что расходы формируются раз в сутки, а счёт выставляется за фактически отработанное время. Сами расчёты осуществляются каждые 5 минут. \n Представленные ниже цены рассчитаны исходя из того, что сервер проработает полный период: час, день, месяц (30 дней).",

    "nav": {
      "private": "Приватный",
      "public": "Публичный"
    },
    "additional": "Стоимость",
    "balancer" : {
      "name" : "Имя папки:",
      "title" : "Правило",
      "hint" : "Имя папки должно быть уникально в рамках хранилища. Длиной от 1 до 63 символов без '/'"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.paragraf {
  margin-bottom: 10px;
  &-up{
    margin-top: 10px;

  }
}

.folder {

  &__content {
    display: flex;
    margin-top: 1rem;
    //justify-content: space-between;
    text-align: left;
    align-items: baseline;

    &-select {
      min-width: 15rem

    }
    &-input {
      margin-left: 1rem
      min-width: 20rem
      //width: 100%

    }


    &-item {
      min-width: 10rem;
      font-size: 20px;
      line-height: 26px;

      +breakpoint(sm-and-up) {
        min-width: 10rem;

      }
    }
  }
}
</style>
