var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time-expired"},[_c('label',{staticClass:"standart-title time-expired__row"},[_vm._v(_vm._s(_vm.$t('title')))]),_c('div',[_c('InputNumber',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('day')),expression:"$t('day')",modifiers:{"top":true}}],staticClass:"time-buttons",staticStyle:{"width":"3rem"},attrs:{"mode":"decimal","min":0,"input-style":{
        'text-align': 'center',
        'max-width': '3rem',
        'min-width': '3rem',
      },"max":6,"button-layout":"vertical"},on:{"blur":function($event){return _vm.onInput('d', $event)}},model:{value:(_vm.defaultLinkExpired.d),callback:function ($$v) {_vm.$set(_vm.defaultLinkExpired, "d", $$v)},expression:"defaultLinkExpired.d"}}),_vm._v(": "),_c('InputNumber',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('hour')),expression:"$t('hour')",modifiers:{"top":true}}],staticClass:"time-buttons",staticStyle:{"width":"3rem"},attrs:{"mode":"decimal","min":0,"input-style":{
        'text-align': 'center',
        'max-width': '3rem',
        'min-width': '3rem',
      },"max":23,"button-layout":"vertical"},on:{"blur":function($event){return _vm.onInput('h', $event)}},model:{value:(_vm.defaultLinkExpired.h),callback:function ($$v) {_vm.$set(_vm.defaultLinkExpired, "h", $$v)},expression:"defaultLinkExpired.h"}}),_vm._v(": "),_c('InputNumber',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('minute')),expression:"$t('minute')",modifiers:{"top":true}}],staticClass:"time-buttons",staticStyle:{"width":"3rem"},attrs:{"mode":"decimal","min":0,"max":59,"button-layout":"vertical","input-style":{
        'text-align': 'center',
        'max-width': '3rem',
        'min-width': '3rem',
      }},on:{"blur":function($event){return _vm.onInput('m', $event)}},model:{value:(_vm.defaultLinkExpired.m),callback:function ($$v) {_vm.$set(_vm.defaultLinkExpired, "m", $$v)},expression:"defaultLinkExpired.m"}}),_vm._v(": "),_c('InputNumber',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('second')),expression:"$t('second')",modifiers:{"top":true}}],staticClass:"time-buttons",staticStyle:{"width":"3rem"},attrs:{"mode":"decimal","min":0,"max":59,"input-style":{
        'text-align': 'center',
        'max-width': '3rem',
        'min-width': '3rem',
      },"button-layout":"vertical"},on:{"blur":function($event){return _vm.onInput('s', $event)}},model:{value:(_vm.defaultLinkExpired.s),callback:function ($$v) {_vm.$set(_vm.defaultLinkExpired, "s", $$v)},expression:"defaultLinkExpired.s"}})],1),_c('label',{staticClass:"standart-text time-expired__row"},[_vm._v(_vm._s(_vm.$t('text')))])])
}
var staticRenderFns = []

export { render, staticRenderFns }