import Vue from 'vue';
import modals from '@/mixins/modals';
import NewFolder from '@/layouts/Stack/pages/Order/NewFolder';
import showErrorModal from '@/mixins/showErrorModal';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
export default {
  mixins: [modals, showErrorModal],
  data() {
    return {
      folder: '',
      currentStorage: '',
    };
  },
  methods: {
    newFolder(payload) {
      this.currentStorage = payload.storage.name;
      const that = this;
      return new Promise(() => {
        this.$modals.open({
          name: 'NewFolder',
          size: 'big',
          component: NewFolder,
          closeOnBackdrop: false,
          props: {
            storage: payload.storage,
            storageid: payload.storageid,
            folders: payload.folders,
          },
          on: {
            change: data => {
              this.folder = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('confirm') },
              on: {
                click: async () => {
                  Vue.set(this.modal, 'component', BaseLoader);
                  Vue.set(this.modal.footer.confirm.props, 'loading', true);
                  let formData = new FormData();
                  formData.append(this.folder, {});
                  const url = await this.sendUrl(this.folder);
                  const params = {
                    url: url,
                    storage: this.storageid,
                    name: this.folder,
                  };
                  await this.onUpload(params)
                    .then(data => {
                      if (data === 200) {
                        this.$toast.add({
                          severity: 'success',
                          summary: 'Создана папка',
                          detail: this.folder,
                          life: 3000,
                        });
                      } else {
                        this.$toast.add({
                          severity: 'error',
                          summary: 'Ошибка',
                          detail: this.folder,
                          life: 3000,
                        });
                      }
                    })
                    .finally(() => {
                      this.folder = '';
                      that.$modals.close();
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
  },
};
